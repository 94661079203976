import ArrowRight from "@/public/icons/ArrowRight.svg";
import BannerContent from "@/components/BannerContentArticle";
import CardArticleHighlight from "@/components/CardArticleHighlight";
import CardArticle from "@/components/CardArticle";
import Link from "next/link";
import researchServices from "@/services/research";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import Head from "next/head";
import { useRouter } from "next/router";

export async function getServerSideProps({ locale }) {
  const responseTag = await researchServices.fetchResourceTags({
    slug: locale,
  });

  const paramsRecentPost = {
    per_page: 4,
    page: 1,
    _embed: 1,
    tags: responseTag[0].id,
  };
  const recentPost = await researchServices.fetchPosts(paramsRecentPost);

  const responseCategories = await researchServices.fetchCategory();
  const categories = responseCategories.filter((c) => c.count > 0);
  const newCategories = await Promise.all(
    categories.map(async (category) => {
      const params = {
        _embed: 1,
        per_page: 4,
        categories: category.id,
        tags: responseTag[0].id,
      };
      const listPost = await researchServices.fetchPosts(params);
      return {
        ...category,
        posts: listPost,
      };
    })
  );

  return {
    props: {
      recentPost: recentPost,
      categories: newCategories,
      ...(await serverSideTranslations(locale)),
    },
  };
}

export default function Research({ categories, recentPost }) {
  const { t } = useTranslation("research");

  const bannerContent = {
    title: t("banner.title"),
    desc: t("banner.desc"),
  };
  return (
    <>
      <Head>
        <title>{t("seo.title")}</title>
        <meta name="title" content={t("seo.title")} />
        <meta name="description" content={t("seo.desc")} />

        <meta property="og:title" content={t("seo.title")} />
        <meta property="og:description" content={t("seo.desc")} />
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}${
            useRouter().locale === "vi" ? "/vi" : ""
          }${useRouter().asPath}`}
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}/ThumbnailCyStack.png`}
        />

        <meta name="twitter:title" content={t("seo.title")} />
        <meta name="twitter:description" content={t("seo.desc")} />
        <meta
          name="twitter:image"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}/ThumbnailCyStack.png`}
        />
      </Head>

      <div className="pb-[250px]">
        <section className="blog-banner bg-black">
          <div className="section-main relative overflow-hidden pt-[120px] pb-[200px] md:pt-10">
            <img
              src="/images/blog/bg-banner.svg"
              className="md:hidden absolute bottom-0 right-0 object-contain"
              alt="CyStack image"
            />
            \
            <div className="container text-white">
              <div className="max-w-[868px]">
                <BannerContent banner={bannerContent} classInput="text-dark" />
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="grid grid-cols-3 gap-12 mb-20 lg:grid-cols-2 md:grid-cols-1 -mt-[100px]">
            {recentPost.map((postItem, index) => (
              <div
                key={index}
                className={`${index === 0 ? "col-span-full" : "col-span-1"}`}
              >
                {index === 0 ? (
                  <CardArticleHighlight
                    defaultThumbnail={"/BlogThumbnail.png"}
                    postItem={postItem}
                    type="research"
                  />
                ) : (
                  <CardArticle
                    postItem={postItem}
                    type="research"
                    defaultThumbnail={"/BlogThumbnail.png"}
                  />
                )}
              </div>
            ))}
          </div>
          {categories.map((item) => (
            <div key={item.id} className="post-category mb-20">
              <div className="flex justify-between items-center mb-20">
                <h2
                  className="md:text-h3"
                  dangerouslySetInnerHTML={{ __html: item.name || "" }}
                ></h2>
                <Link href={`/research/category/${item.slug}?id=${item.id}`}>
                  <div className="flex items-center text-dark hover:text-primary-500 hover:cursor-pointer whitespace-nowrap">
                    <span>{t("button.view_all", { ns: "common" })}</span>
                    <ArrowRight className="w-6 h-6 object-contain" />
                  </div>
                </Link>
              </div>
              <div className="grid grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-12">
                {item.posts.map((postItem, index) => (
                  <div
                    key={index}
                    className={`${
                      index === 0 ? "col-span-full" : "col-span-1"
                    }`}
                  >
                    {index === 0 ? (
                      <CardArticleHighlight
                        defaultThumbnail={"/BlogThumbnail.png"}
                        postItem={postItem}
                        type="research"
                      />
                    ) : (
                      <CardArticle
                        postItem={postItem}
                        type="research"
                        defaultThumbnail={"/BlogThumbnail.png"}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
